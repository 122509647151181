// Copyright © 2024 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { ReactNode } from "react"
import afaforsakring from "../../../../images/adopters/afaforsakring.svg"
import allmyfunds from "../../../../images/adopters/allmyfunds.svg"
import amplitude from "../../../../images/adopters/amplitude.svg"
import anaconda from "../../../../images/adopters/anaconda.png"
import arduino from "../../../../images/adopters/arduino.svg"
import arkhn from "../../../../images/adopters/arkhn.svg"
import asurion from "../../../../images/adopters/asurion.svg"
import axelspringer from "../../../../images/adopters/axelspringer.png"
import backmarket from "../../../../images/adopters/backmarket.svg"
import blues from "../../../../images/adopters/blues.svg"
import buffalo from "../../../../images/adopters/buffalo.svg"
import buhta from "../../../../images/adopters/buhta.svg"
import buzzstream from "../../../../images/adopters/buzzstream.svg"
import casavo from "../../../../images/adopters/casavo.svg"
import cisco from "../../../../images/adopters/cisco.png"
import clickup from "../../../../images/adopters/clickup.svg"
import cloudbear from "../../../../images/adopters/cloudbear.svg"
import commitzero from "../../../../images/adopters/commitzero.svg"
import connctd from "../../../../images/adopters/connctd.svg"
import contentg from "../../../../images/adopters/contentg.png"
import contraste from "../../../../images/adopters/contraste.svg"
import dakewe from "../../../../images/adopters/dakewe.png"
import datadetect from "../../../../images/adopters/datadetect.svg"
import datarobot from "../../../../images/adopters/datarobot.svg"
import dfg from "../../../../images/adopters/dfg.svg"
import dyrector from "../../../../images/adopters/dyrector.svg"
import edotcom from "../../../../images/adopters/edotcom.png"
import elfsquad from "../../../../images/adopters/elfsquad.svg"
import elphinstone from "../../../../images/adopters/elphinstone.webp"
import emdyn from "../../../../images/adopters/emdyn.svg"
import enode from "../../../../images/adopters/enode.svg"
import factly from "../../../../images/adopters/factly.svg"
import fandom from "../../../../images/adopters/fandom.svg"
import festo from "../../../../images/adopters/festo.svg"
import flippa from "../../../../images/adopters/flippa.svg"
import fritinc from "../../../../images/adopters/fritinc.png"
import gameverse from "../../../../images/adopters/gameverse.svg"
import hanko from "../../../../images/adopters/hanko.svg"
import hashicorp from "../../../../images/adopters/hashicorp.svg"
import henryford from "../../../../images/adopters/henryford.svg"
import hgv from "../../../../images/adopters/hgv.png"
import honeypotz from "../../../../images/adopters/honeypotz.png"
import hootsuite from "../../../../images/adopters/hootsuite.svg"
import ibm from "../../../../images/adopters/ibm.svg"
import idealo from "../../../../images/adopters/idealo.svg"
import ignite from "../../../../images/adopters/ignite.svg"
import inhand from "../../../../images/adopters/inhand.png"
import injixo from "../../../../images/adopters/injixo.svg"
import inmusic from "../../../../images/adopters/inmusic.svg"
import jotta from "../../../../images/adopters/jotta.svg"
import keysight from "../../../../images/adopters/keysight.svg"
import kincloud from "../../../../images/adopters/kincloud.png"
import klarna from "../../../../images/adopters/klarna.png"
import kyma from "../../../../images/adopters/kyma.svg"
import lavanet from "../../../../images/adopters/lavanet.svg"
import linecorp from "../../../../images/adopters/linecorp.png"
import lumen from "../../../../images/adopters/lumen.svg"
import lunasec from "../../../../images/adopters/lunasec.svg"
import maxroll from "../../../../images/adopters/maxroll.png"
import microscan from "../../../../images/adopters/microscan.png"
import monstar from "../../../../images/adopters/monstar.svg"
import mydesigns from "../../../../images/adopters/mydesigns.svg"
import myio from "../../../../images/adopters/myio.png"
import nablaflow from "../../../../images/adopters/nablaflow.svg"
import nortal from "../../../../images/adopters/nortal.svg"
import novaposhta from "../../../../images/adopters/novaposhta.svg"
import nuclino from "../../../../images/adopters/nuclino.svg"
import openbankproject from "../../../../images/adopters/openbankproject.png"
import optage from "../../../../images/adopters/optage.png"
import ordermygear from "../../../../images/adopters/ordermygear.svg"
import osint from "../../../../images/adopters/osint.png"
import ovhcloud from "../../../../images/adopters/ovhcloud.svg"
import padis from "../../../../images/adopters/padis.svg"
import paralus from "../../../../images/adopters/paralus.svg"
import piratpartiet from "../../../../images/adopters/piratpartiet.png"
import planck from "../../../../images/adopters/planck.svg"
import porscheinformatik from "../../../../images/adopters/porscheinformatik.svg"
import postnord from "../../../../images/adopters/postnord.svg"
import predibase from "../../../../images/adopters/predibase.svg"
import psu from "../../../../images/adopters/psu.svg"
import pydio from "../../../../images/adopters/pydio.png"
import quickplay from "../../../../images/adopters/quickplay.png"
import r2devops from "../../../../images/adopters/r2devops.svg"
import rabbit from "../../../../images/adopters/rabbit.svg"
import racecar from "../../../../images/adopters/racecar.svg"
import raspberrypi from "../../../../images/adopters/raspberrypi.svg"
import renaissance from "../../../../images/adopters/renaissance.svg"
import reveliolabs from "../../../../images/adopters/reveliolabs.svg"
import reyah from "../../../../images/adopters/reyah.svg"
import sainsburys from "../../../../images/adopters/sainsburys.svg"
import sdaletech from "../../../../images/adopters/sdaletech.svg"
import securityonion from "../../../../images/adopters/securityonion.svg"
import semalt from "../../../../images/adopters/semalt.svg"
import serlo from "../../../../images/adopters/serlo.svg"
import shadow from "../../../../images/adopters/shadow.svg"
import skyla from "../../../../images/adopters/skyla.png"
import socgen from "../../../../images/adopters/socgen.svg"
import soundthinking from "../../../../images/adopters/soundthinking.svg"
import spiribo from "../../../../images/adopters/spiribo.svg"
import sscspace from "../../../../images/adopters/sscspace.svg"
import stackspin from "../../../../images/adopters/stackspin.svg"
import strivacity from "../../../../images/adopters/strivacity.svg"
import sumup from "../../../../images/adopters/sumup.svg"
import svenskaspel from "../../../../images/adopters/svenskaspel.svg"
import swan from "../../../../images/adopters/swan.svg"
import switchIMG from "../../../../images/adopters/switch.png"
import synology from "../../../../images/adopters/synology.svg"
import sysgroup from "../../../../images/adopters/sysgroup.svg"
import tdcgroup from "../../../../images/adopters/tdcgroup.svg"
import tesla from "../../../../images/adopters/tesla.svg"
import themediatrust from "../../../../images/adopters/themediatrust.png"
import tieriv from "../../../../images/adopters/tieriv.svg"
import tribecrm from "../../../../images/adopters/tribecrm.svg"
import tulip from "../../../../images/adopters/tulip.svg"
import unionai from "../../../../images/adopters/unionai.svg"
import unternehmertum from "../../../../images/adopters/unternehmertum.svg"
import vipaso from "../../../../images/adopters/vipaso.png"
import vmware from "../../../../images/adopters/vmware.svg"
import weave from "../../../../images/adopters/weave.svg"
import zezam from "../../../../images/adopters/zezam.svg"

const customersData: Array<{
  title: string
  image: ReactNode
  url?: string
  quote?: {
    text: string
    author: string
    position?: string
    href?: string
  }
  caseStudyUrl?: string
}> = [
  {
    title: "Anaconda",
    image: anaconda,
    url: "https://anaconda.com/",
    caseStudyUrl: "/anaconda-case-study/",
  },
  {
    title: "Axel Springer",
    image: axelspringer,
    url: "https://www.axelspringer.com/",
  },
  {
    title: "Cisco",
    image: cisco,
    url: "https://www.cisco.com/",
  },
  {
    title: "HGV",
    image: hgv,
    url: "https://www.hgv.it/",
    caseStudyUrl: "https://youtu.be/DCOcKovz6Hk",
  },
  {
    title: "Fandom",
    image: fandom,
    url: "https://fandom.com/",
    caseStudyUrl: "/fandom-case-study/",
    quote: {
      text: "We have to deal with sudden increases in traffic and our system needs to handle that, especially when we are talking about authentication which is always in a critical path for every request a user.",
      author: "Łukasz Harasimowicz",
      position: "Platform Team, Fandom",
    },
  },
  {
    title: "IBM",
    image: ibm,
    url: "https://www.ibm.com/security",
  },
  {
    title: "Klarna",
    image: klarna,
    url: "https://www.klarna.com/",
  },
  {
    title: "VMware",
    image: vmware,
    url: "https://www.vmware.com/",
  },
  {
    title: "Raspberry Pi",
    image: raspberrypi,
    url: "https://www.raspberrypi.org/",
  },
  {
    title: "Ignite",
    image: ignite,
    url: "https://www.ignite.no/",
    caseStudyUrl: "https://youtu.be/e5jjn-fTD-4",
  },
  {
    title: "Sainsbury's",
    image: sainsburys,
    url: "https://www.sainsburys.co.uk/",
    quote: {
      text: "At Sainsbury’s Tech we use Ory tools to power our identity platform, My ID. My ID handles identity for major brands across the group including Nectar, Groceries On-line and SmartShop.",
      author: "Paul Harman",
      position: "Engineering Manager",
    },
  },
  {
    title: "Societe Generale",
    image: socgen,
    url: "https://www.societegenerale.com/fr",
  },
  {
    title: "OSINT",
    image: osint,
    url: "https://osint.industries/",
    caseStudyUrl: "/osint-case-study/",
  },
  {
    title: "Maxroll",
    image: maxroll,
    url: "https://www.maxroll.gg/",
    quote: {
      text: "With Ory implementing MFA was just matter of ticking a checkbox and then all our users had the option to use TOTP/WebAuthn, it just worked like that - whereas at other providers you get charged premium for basic security features like multi-factor authentication.",
      author: "Stephen Hoogendijk",
      position: "CTO",
    },
    caseStudyUrl: "/maxroll-case-study/",
  },
  {
    title: "SumUp",
    image: sumup,
    url: "https://sumup.com/",
  },
  {
    title: "Shadow",
    image: shadow,
    url: "https://shadow.tech/",
  },
  {
    title: "zezam",
    image: zezam,
    url: "https://www.zezam.io/",
    quote: {
      text: "The engineers are approachable, Ory openly communicates plans and it feels you are heard even as a small customer as opposed to other providers.",
      author: "Daniel Stockhammer",
      position: "CTO, zezam.io",
    },
    caseStudyUrl: "/zezam-case-study/",
  },
  {
    title: "inMusic",
    image: inmusic,
    url: "https://inmusicbrands.com/",
  },
  {
    title: "OVHcloud",
    image: ovhcloud,
    url: "https://www.ovhcloud.com/",
  },
  {
    title: "TIER IV",
    image: tieriv,
    url: "https://tier4.jp/en/",
  },
  {
    title: "HashiCorp",
    image: hashicorp,
    url: "https://www.hashicorp.com/",
  },
  {
    title: "Tesla",
    image: tesla,
    url: "https://www.tesla.com/",
  },
  {
    title: "Pennsylvania State University",
    image: psu,
    url: "https://www.psu.edu/",
  },
  {
    title: "BackMarket",
    image: backmarket,
    url: "https://www.backmarket.com/",
  },
  {
    title: "BluesWireless",
    image: blues,
    url: "https://blues.io/",
  },
  {
    title: "Nortal",
    image: nortal,
    url: "https://nortal.com",
  },
  {
    title: "Arduino",
    image: arduino,
    url: "https://arduino.cc",
  },
  {
    title: "Project Zero by Commit",
    image: commitzero,
    url: "https://getzero.dev",
    quote: {
      text: "Ory made it simple to add stable, secure user management and access control to our systems. No more reinventing the wheel!",
      author: "Bill Monkman",
      position: "Chief Architect, Commit",
    },
  },
  {
    title: "Hootsuite",
    image: hootsuite,
    url: "https://hootsuite.com",
  },
  {
    title: "Amplitude",
    image: amplitude,
    url: "https://amplitude.com",
  },
  {
    title: "Factly",
    image: factly,
    url: "https://factlylabs.com",
    quote: {
      text: "At Factly, we were looking for a lightweight alternative to Keycloak that would meet all our IAM needs across multiple applications. Ory, written in Golang is the best option out there that is simple and performant.",
      author: "Shashi Deshetti",
      position: "Co-Founder",
    },
  },
  {
    title: "Buhta",
    image: buhta,
    url: "https://buhta.com",
  },
  {
    title: "Casavo",
    image: casavo,
    url: "https://casavo.com/",
  },
  {
    title: "All My Funds",
    image: allmyfunds,
    url: "https://cashdeck.com.au",
  },
  {
    title: "ClickUp",
    image: clickup,
    url: "https://clickup.com/",
  },
  {
    title: "Padis",
    image: padis,
    url: "https://padis.io/",
    quote: {
      author: "Akibur Rahman",
      position: "System Architect",
      text: "Ory products consider all modern technical aspects and it was a perfect fit for our system. Integration was easy and we are able to customize based on our requirements.",
    },
  },
  {
    title: "Cloudbear",
    image: cloudbear,
    url: "https://cloudbear.eu",
  },
  {
    title: "Contraste",
    image: contraste,
    url: "https://contraste.com",
  },
  {
    title: "R2Devops",
    image: r2devops,
    url: "https://r2devops.io",
    quote: {
      text: "Ory Kratos is a huge time saver. It allows us to implement identity management in R2Devops infrastructure at rocket speed. 🚀",
      author: "Thomas Boni",
      position: "CTO",
    },
  },
  {
    title: "Vipaso GmbH",
    image: vipaso,
    url: "https://vipaso.io/",
  },
  {
    title: "Serlo",
    image: serlo,
    url: "https://de.serlo.org/",
  },
  {
    title: "myio",
    image: myio,
    url: "https://myio.com.br/",
    quote: {
      text: "OAuth2 with Ory is so smooth; I probably spent more time removing the old OAuth2 implementation than I did wiring up Ory into my app.",
      author: "Yan Gabriel Minário",
      position: "CTO",
    },
  },
  {
    title: "dyrector.io",
    image: dyrector,
    url: "https://dyrector.io",
  },
  {
    title: "Elphinstone",
    image: elphinstone,
    url: "https://elphinstone.us/",
  },
  {
    title: "Connctd",
    image: connctd,
    url: "https://connctd.com/",
    quote: {
      text: "Ory allowed us to use enterprise grade IAM features without cloud vendor lock in. Together with the other projects from Ory we have an excellent basis to build competitive products on.",
      author: "Till Klocke",
      position: "Co-Founder",
    },
  },
  {
    title: "Enode",
    image: enode,
    url: "https://enode.com/",
    quote: {
      text: "We rely on Ory to be our OAuth2 provider and it fits perfectly into our infrastructure, is hardened, and aligns with our principles. Ory is a great fit for us.",
      author: "Benjamin Kristensen",
      position: "Senior Software Engineer",
    },
  },
  {
    title: "Flippa",
    image: flippa,
    url: "https://flippa.com/",
  },
  // {
  //   title: "Galoy",
  //   image: galoy,
  //   url: "https://galoy.io/",
  // },
  {
    title: "Kyma",
    image: kyma,
    url: "https://kyma-project.io",
  },
  {
    title: "Union.ai",
    image: unionai,
    url: "https://union.ai/",
    quote: {
      text: "Ory is a project where they are creating a wheel once for everyone.#HackerProof",
      author: "Yuvraj Yadav",
      position: "Software Engineer",
    },
  },
  {
    title: "MyDesigns",
    image: mydesigns,
    url: "https://mydesigns.io/",
  },
  {
    title: "LunaSec",
    image: lunasec,
    url: "https://www.lunasec.io",
  },
  {
    title: "OrderMyGear",
    image: ordermygear,
    url: "https://ordermygear.com",
  },
  {
    title: "Paralus",
    image: paralus,
    url: "https://paralus.io",
  },
  {
    title: "HoneyPotz",
    image: honeypotz,
    url: "https://www.honeypotz.net/",
  },
  {
    title: "Rabbit",
    image: rabbit,
    url: "https://rabbit.co.th/",
  },
  {
    title: "Reyah",
    image: reyah,
    url: "https://reyah.eu",
  },
  {
    title: "Sdaletech",
    image: sdaletech,
    url: "https://sdaletech.com/",
  },
  {
    title: "Security Onion Solutions",
    image: securityonion,
    url: "https://securityonionsolutions.com",
  },
  {
    title: "Semalt",
    image: semalt,
    url: "https://semalt.com/",
  },
  {
    title: "Skyla",
    image: skyla,
    url: "https://skyla-school.de/",
  },
  {
    title: "Spiri.bo",
    image: spiribo,
    url: "https://spiri.bo",
  },
  {
    title: "Stackspin",
    image: stackspin,
    url: "https://stackspin.net",
  },
  {
    title: "Strivacity",
    image: strivacity,
    url: "https://strivacity.com",
  },
  {
    title: "Tulip Retail",
    image: tulip,
    url: "https://tulip.com",
  },
  {
    title: "EDOTCOM",
    image: edotcom,
    url: "https://edotcom.be/",
    quote: {
      text: "Ory is a flexible B2C solution that can be deployed easily in different type of infrastructure (on-prem or cloud) and platform (Kubernetes, Docker, VMs or BM). Depending on the use case, you can quickly intergate it in a single or multi-domain mode and isolate the backend for each one of your brand or client. You can expose it behind an API Gateway and Ingress Controller for Kubernetes easily to control incoming trafics or API calls.",
      author: "Ntambwe Kibambe",
      position: "CTO & Founder",
    },
  },
  {
    title: "Datadetect",
    image: datadetect,
    url: "https://unifiedglobalarchiving.com/data-detect/",
  },
  {
    title: "Asurion",
    image: asurion,
    url: "https://www.asurion.com/",
  },
  {
    title: "Pirate Party (Piratpartiet)",
    image: piratpartiet,
    url: "https://piratpartiet.se/",
    quote: {
      text: "We choose Ory because it is open-source, does not force an UI on us, and is built for a modern cloud stack. Ory matches how our organization works and being able to build our own system is a big advantage compared to other solutions.",
      author: "Jakob Sinclair",
      position: "IT-Expert",
    },
  },
  {
    title: "NovaPoshta",
    image: novaposhta,
    url: "https://novaposhta.ua/",
  },
  {
    title: "BuzzStream",
    image: buzzstream,
    url: "https://www.buzzstream.com/",
  },
  {
    title: "Lumen",
    image: lumen,
    url: "https://www.lumen.com/",
  },
  {
    title: "Elfsquad",
    image: elfsquad,
    url: "https://www.elfsquad.io/",
  },
  {
    title: "Nablaflow",
    image: nablaflow,
    url: "https://nablaflow.io/",
  },
  {
    title: "Predibase",
    image: predibase,
    url: "https://predibase.com/",
  },
  {
    title: "Hanko",
    image: hanko,
    url: "https://www.hanko.io/",
  },
  {
    title: "QuickPlay",
    image: quickplay,
    url: "https://www.quickplay.com/",
  },
  {
    title: "Idealo",
    image: idealo,
    url: "https://www.idealo.de/",
  },
  {
    title: "InHandCloud",
    image: inhand,
    url: "https://www.inhandnetworks.com/",
  },
  {
    title: "Weave",
    image: weave,
    url: "https://www.getweave.com/",
  },
  {
    title: "Lavanet",
    image: lavanet,
    url: "https://www.lavanet.xyz/",
  },
  {
    title: "Nuclino",
    image: nuclino,
    url: "https://www.nuclino.com/",
  },
  {
    title: "PostNord",
    image: postnord,
    url: "https://www.postnord.dk/en",
  },
  {
    title: "planck",
    image: planck,
    url: "https://www.planck.security/",
  },
  {
    title: "Reveliolabs",
    image: reveliolabs,
    url: "https://www.reveliolabs.com/",
  },
  {
    title: "Gameverse",
    image: gameverse,
    url: "https://www.gameverse.app/",
  },
  {
    title: "SvenskaSpel",
    image: svenskaspel,
    url: "https://www.svenskaspel.se/",
  },
  {
    title: "Swan",
    image: swan,
    url: "https://www.swan.io/",
  },
  {
    title: "Synology",
    image: synology,
    url: "https://www.synology.com/",
  },
  {
    title: "The Media Trust",
    image: themediatrust,
    url: "https://www.themediatrust.com/",
  },
  {
    title: "TribeCRM",
    image: tribecrm,
    url: "https://www.tribecrm.nl/",
  },
  {
    title: "Renaissance",
    image: renaissance,
    url: "https://www.renaissance.com/",
  },
  {
    title: "Racecar",
    image: racecar,
    url: "https://www.racecar.com/",
  },
  {
    title: "Injixo",
    image: injixo,
    url: "https://www.injixo.com/",
  },
  {
    title: "Dakewe Biotech",
    image: dakewe,
    url: "https://dakewe.com/",
  },
  {
    title: "Monstar",
    image: monstar,
    url: "https://monstar.ch/",
  },
  {
    title: "DataRobot",
    image: datarobot,
    url: "https://www.datarobot.com/",
  },
  {
    title: "SWITCH",
    image: switchIMG,
    url: "https://www.switch.ch/",
  },
  {
    title: "Open Bank Project",
    image: openbankproject,
    url: "https://www.openbankproject.com/",
  },
  {
    title: "Center for Computational Research - University at Buffalo",
    image: buffalo,
    url: "https://www.buffalo.edu/ccr.html",
  },
  {
    title: "UnternehmerTUM",
    image: unternehmertum,
    url: "https://www.unternehmertum.de/",
  },
  {
    title: "TDC Group",
    image: tdcgroup,
    url: "https://www.tdcgroup.com/",
  },
  {
    title: "Kincloud",
    image: kincloud,
    url: "https://kincloud.io/",
  },
  {
    title: "SysGroup",
    image: sysgroup,
    url: "https://www.sysgroup.com/",
  },
  {
    title: "SSC Space",
    image: sscspace,
    url: "https://www.sscspace.com/",
  },
  {
    title: "Pydio",
    image: pydio,
    url: "https://pydio.com/",
  },
  {
    title: "Porsche Informatik",
    image: porscheinformatik,
    url: "https://www.porscheinformatik.at/",
  },
  {
    title: "LINE Corporation",
    image: linecorp,
    url: "https://linecorp.com/",
  },
  {
    title: "Keysight Technologies",
    image: keysight,
    url: "https://www.keysight.com/",
  },
  {
    title: "AFA Försäkring",
    image: afaforsakring,
    url: "https://www.afaforsakring.se/",
  },
  {
    title: "Henry Ford Health System",
    image: henryford,
    url: "https://www.henryford.com/",
  },
  {
    title: "Jotta",
    image: jotta,
    url: "https://www.jotta.no/",
  },
  {
    title: "Frit Industries, Inc.",
    image: fritinc,
    url: "https://fritind.com/",
  },
  {
    title: "Festo",
    image: festo,
    url: "https://www.festo.com/",
  },
  {
    title: "EMDyn",
    image: emdyn,
    url: "https://www.emdyn.com/",
  },
  {
    title: "Soundthinking",
    image: soundthinking,
    url: "https://www.soundthinking.com/",
  },
  {
    title: "Microscan Systems",
    image: microscan,
    url: "https://www.microscan.co.in/",
  },
  {
    title: "DFG",
    image: dfg,
    url: "https://www.dfg.de/",
  },
  {
    title: "Arkhn",
    image: arkhn,
    url: "https://www.arkhn.org/",
  },
  {
    title: "ContentGuppy",
    image: contentg,
    url: "https://contentguppy.com/",
  },
  {
    title: "Optage",
    image: optage,
    url: "https://optage.co.jp/",
  },
]

export default customersData
